import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchPlace } from "../../redux/actions/placeAction";
import { Card, Pagination } from "antd";
import noImage from "../../assets/No_Image_Available.jpg";
const loadCSS = () => import("./places.css");

const Places = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const category = location.pathname.split("/")[3] || "";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const { place, loading, error } = useSelector((state) => state.place);

  useEffect(() => {
    dispatch(fetchPlace(category, currentPage));
    loadCSS();
  }, [dispatch, category, currentPage]);

  const handlePageChange = (perPage) => {
    setCurrentPage(perPage);
    dispatch(fetchPlace(perPage));
  };

  if (loading)
    return <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>;
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <>
      <div>
        <div className="place-container">
          {place.length > 0 ? (
            place.map((placeItem) => (
              <Link
                to={`/place/${placeItem.id}`}
                key={placeItem.id}
                style={{ textDecoration: "none" }}
              >
                <Card
                  key={placeItem.id}
                  className="place-card"
                  cover={
                    // <img
                    //   className="place-image"
                    //   alt={placeItem.name}
                    //   src={placeItem.image_url || noImage}
                    //   style={{ objectFit: "cover" }}
                    // />
                    placeItem.image_url && placeItem.image_url.length > 0 ? (
                      <img
                        className="place-image"
                        alt={placeItem.name}
                        src={placeItem.image_url}
                      />
                    ) : (
                      <img
                        className="place-image"
                        alt="No Image Available"
                        src={noImage}
                      />
                    )
                  }
                >
                  <Card.Meta
                    title={`${placeItem.name}` || ""}
                    description={
                      <div className="place-content">
                        {placeItem.location ? (
                          <p className="place-date">
                            <span>Հասցե` </span>
                            {placeItem.location}
                          </p>
                        ) : (
                          " "
                        )}
                        {placeItem.start_with_federation ? (
                          <p className="place-date">
                            <span>Համագործակցության սկիզբ՝ </span>
                            {placeItem.start_with_federation.split("T")[0]}
                          </p>
                        ) : (
                          " "
                        )}
                      </div>
                    }
                  />
                </Card>
              </Link>
            ))
          ) : (
            <p style={{ height: "100vh" }}>Ոչինչ չի գտնվել։</p>
          )}
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={place.length || 0}
          onChange={handlePageChange}
          className="pagination"
        />
      </div>
    </>
  );
};

export default Places;
