import {
  FETCH_PLACE_REQUEST,
  FETCH_PLACE_SUCCESS,
  FETCH_PLACE_FAILURE,
  FETCH_PLACE_DETAILS_REQUEST,
  FETCH_PLACE_DETAILS_SUCCESS,
  FETCH_PLACE_DETAILS_FAILURE,
} from "../actions/placeAction";
import {
  RATE_PLACE_FAILURE,
  RATE_PLACE_REQUEST,
  RATE_PLACE_SUCCESS,
} from "../actions/ratePlaceAction";

const initialState = {
  place: [],
  placeDetail: null,
  loading: false,
  error: null,
};

const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLACE_REQUEST:
    case FETCH_PLACE_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PLACE_SUCCESS:
      return { ...state, loading: false, place: action.payload };
    case FETCH_PLACE_DETAILS_SUCCESS:
      return { ...state, loading: false, placeDetail: action.payload };
    case FETCH_PLACE_FAILURE:
    case FETCH_PLACE_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RATE_PLACE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RATE_PLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        rateMessage: action.payload.message,
      };

    case RATE_PLACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default placeReducer;
