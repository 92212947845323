import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { fetchUsers } from "../../redux/actions/usersActions";
import "./users.css";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Pagination, Select } from "antd";
import noImagePerson from "../../assets/noImagePerson.png";

const { Search } = Input;
const Users = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const category = location.pathname.split("/")[3] || "";
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [role_type, setRole_type] = useState("Դերը");
  const [sex, setSex] = useState("all");
  const [pageSize, setPageSize] = useState(1);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const { users, topUsers, loading, error } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(fetchUsers(category, currentPage, "", sex, role_type));
  }, [dispatch, category, currentPage, sex, role_type]);
  if (loading)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է ...</p>
    );
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    dispatch(fetchUsers(category, currentPage, value));
  };
  const handlePageChange = (perPage) => {
    setCurrentPage(perPage);
  };

  return (
    <>
      <div className="datepicker-container datepickerEvents-container">
        <Select
          className="selectSex"
          defaultValue="all"
          value={sex}
          onChange={(value) => setSex(value)}
        >
          <option value="all">Բոլորը</option>
          <option value="female">Իգական</option>
          <option value="male">Արական</option>
        </Select>
        <Select
          className="selectSex"
          defaultValue="Դերը"
          value={role_type}
          onChange={(value) => setRole_type(value)}
        >
          <Select.Option value="all">Բոլորը</Select.Option>
          <Select.Option value="trainer">Մարզիչ</Select.Option>
          <Select.Option value="referee">Մրցավար</Select.Option>
        </Select>
        <Search
          className="input"
          placeholder="Որոնել"
          enterButton={<SearchOutlined />}
          value={searchTerm}
          onSearch={handleSearchChange}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="mainContainer">
        <div className="usersListSection">
          <div className="usersList">
            {users.data && users.data?.length > 0 ? (
              users.data.map((user) => (
                <div key={user.id} className="usersCard">
                  <Link
                    to={`/judo-users/${user.id}`}
                    key={user.id}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="usersIamge"
                      // src={
                      //   `${API_BASE_URL}/storage/${user?.image?.file_path}` ||
                      //   noImagePerson
                      // }
                      src={
                        user?.image?.file_path
                          ? `${API_BASE_URL}/storage/${user.image.file_path}`
                          : noImagePerson
                      }
                      alt={user.firstname}
                    />
                    <div className="usersContent">
                      <p>
                        {user.firstname} {user.surname}
                      </p>
                      <p>{user.role_type}</p>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Ոչ ոք չի գտնվել։</p>
            )}
          </div>
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={users.total || 0}
          onChange={handlePageChange}
          className="pagination"
        />
      </div>
    </>
  );
};

export default Users;
