import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPlaceById } from "../../../redux/actions/placeAction";
import { Rate, Modal, message } from "antd";
import IPService from "../../../services/ipService";
import { ratePlaceById } from "../../../redux/actions/ratePlaceAction";
import noImage from "../../../assets/No_Image_Available.jpg";

const PlacesDetails = () => {
  const loadCSS = () => import("./placesDetails.css");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { placeDetail, loading, error } = useSelector((state) => state.place);
  const [hasRated, setHasRated] = useState(false);
  const [rating, setRating] = useState(0);
  const [averageRating, setAverageRating] = useState(
    placeDetail?.average_rating || 0
  );

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchPlaceById(id));
    loadCSS();
    // }, [dispatch, id]);

    IPService.getIPAddress().then((ipAddress) => {
      const ratedUsers = JSON.parse(localStorage.getItem("ratedUsers")) || {};
      if (ratedUsers[ipAddress]?.includes(id)) {
        setHasRated(true);
      }
    });
  }, [dispatch, id]);

  const handleRate = async (value) => {
    const ipAddress = await IPService.getIPAddress();
    if (!ipAddress) {
      message.error("Unable to fetch IP address.");
      return;
    }

    // Check if user has already rated this user
    const ratedUsers = JSON.parse(localStorage.getItem("ratedUsers")) || {};
    if (ratedUsers[ipAddress]?.includes(id)) {
      message.warning("You have already rated this user.");
      return;
    }

    setRating(value);

    // Dispatch the rate action
    dispatch(ratePlaceById(id, value, ipAddress));

    // Update local storage to mark the user as rated
    ratedUsers[ipAddress] = [...(ratedUsers[ipAddress] || []), id];
    localStorage.setItem("ratedUsers", JSON.stringify(ratedUsers));
    setIsModalVisible(true);
    setHasRated(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  if (loading)
    return <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>;
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="usersDetails-container">
      {placeDetail ? (
        <>
          <div className="usersDetails-header">
            <img
              className="usersImage"
              src={
                placeDetail?.image?.file_path
                  ? `${API_BASE_URL}/storage/${placeDetail.image?.file_path}`
                  : noImage
              }
              alt={placeDetail.name}
            />
            <div className="usersDetails-info">
              <h1 className="usersDetailsName">{placeDetail.name}</h1>
              {placeDetail.location ? (
                <p className="usersWeight">
                  <span>Հասցե՝ </span> {placeDetail.location}
                </p>
              ) : (
                ""
              )}

              {placeDetail.firstname && placeDetail.lastname ? (
                <p className="usersWeight">
                  <span>Գլխավոր մենեջեր ՝</span> {placeDetail.firstname}{" "}
                  {placeDetail.lastname}
                </p>
              ) : (
                ""
              )}

              {placeDetail.start_date ? (
                <p className="usersWeight">
                  <span>Կազմավորվել է՝ </span>{" "}
                  {placeDetail.start_date.split("T")[0]}
                </p>
              ) : (
                ""
              )}

              {placeDetail.start_with_federation ? (
                <p className="usersWeight">
                  <span>Համագործակցության սկիզբ՝ </span>{" "}
                  {placeDetail.start_with_federation.split("T")[0]}
                </p>
              ) : (
                ""
              )}

              {placeDetail.children !== undefined ? (
                <p className="usersWeight">
                  <span>Անդամների քանակ՝ </span> {placeDetail.children}
                </p>
              ) : (
                ""
              )}

              {placeDetail.phone ? (
                <p className="usersWeight">
                  <span>Հեռ.՝ </span> {placeDetail.phone}
                </p>
              ) : (
                ""
              )}

              {placeDetail.email ? (
                <p className="usersWeight">
                  <span>Էլ. հասցե՝ </span> {placeDetail.email}
                </p>
              ) : (
                ""
              )}
              <div className="rating-section">
                {!hasRated ? (
                  <>
                    <p className="usersWeight">
                      <span style={{ color: "red" }}>Գնահատել</span>
                    </p>
                    <Rate onChange={handleRate} value={rating} />
                  </>
                ) : (
                  <div>
                    <p className="usersWeight">
                      <span>Վարկանիշ՝ </span> {placeDetail.average_rate}
                    </p>
                    <Rate
                      disabled
                      value={averageRating}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
              </div>
              <Modal
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
              >
                <p>Շնորհակալություն գնահատելու համար!</p>
              </Modal>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PlacesDetails;
