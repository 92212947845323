import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembers } from '../../../redux/actions/organizationActions';
import './management.css';
import noImage from "../../../assets/No_Image_Available.jpg";
const Management = () => {
  const dispatch = useDispatch();
  const { loading, members, error } = useSelector((state) => state.organization);

  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  // Flatten members recursively
  const flattenMembers = (members) => {
    let result = [];
    members.forEach((member) => {
      result.push(member);
      if (member.children && member.children.length > 0) {
        result = result.concat(flattenMembers(member.children));
      }
    });
    return result;
  };

  // Group flattened members by role
  const groupMembersByRole = (members) => {
    return members.reduce((acc, member) => {
      const role = member.role || 'Unassigned';
      if (!acc[role]) acc[role] = [];
      acc[role].push(member);
      return acc;
    }, {});
  };

  const flattenedMembers = flattenMembers(members);
  const groupedMembers = groupMembersByRole(flattenedMembers);

  return (
      <div className="management-container">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
            <ul className="role-list">
              {Object.entries(groupedMembers).map(([role, members]) => (
                  <li key={role} className="role-item">
                    <strong className="role-title">{role}</strong>
                    <ul className="member-list">
                      {members.map((member) => (
                          <li className="member-item" key={member.id}>
                            <img
                                src={member.image ? member.image : noImage}
                                alt={`${member.name}'s picture`}
                                className="member-image"
                            />
                            <div className="member-details">
                              <span className="member-name">{member.name}</span>
                              {member.description && <span className="member-description">({member.description})</span>}
                            </div>
                          </li>
                      ))}
                    </ul>
                  </li>
              ))}
            </ul>
        )}
      </div>
  );
};

export default Management;
