import {
  FETCH_VIDEO_REQUEST,
  FETCH_VIDEO_SUCCESS,
  FETCH_VIDEO_FAILURE,
  FETCH_VIDEO_DETAILS_REQUEST,
  FETCH_VIDEO_DETAILS_SUCCESS,
  FETCH_VIDEO_DETAILS_FAILURE,
} from "../actions/videoActions";

const initialState = {
  video: [],
  videoDetails: null,
  loading: false,
  error: null,
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST:
    case FETCH_VIDEO_DETAILS_REQUEST:
      return { ...state, loading: true, videoDetails: null  };

    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        video: action.payload,
      };

    case FETCH_VIDEO_DETAILS_SUCCESS:
      return { ...state, loading: false, videoDetails: action.payload };

    case FETCH_VIDEO_FAILURE:
    case FETCH_VIDEO_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default videoReducer;
