import React from 'react';
import './partners.css';
import eujudo from './partnersImg/eju.png';
import intjudo from './partnersImg/intJudo.png';
import kgms from './partnersImg/kgms.png';
import olimp from './partnersImg/olimp.png';

const partners = [
  { src: kgms, alt: 'ՀՀ ԿԳՄՍ', url: 'https://escs.am/am/' },
  { src: olimp, alt: 'ՀԱՕԿ', url: 'http://www.arnnoc.am/' },
  { src: intjudo , alt: 'IJF', url: 'https://www.ijf.org/' },
  { src: eujudo, alt: 'EJU', url: 'https://www.eju.net/' },
];


const Partners = () => {
  return ( <div className='main-container'>
    <h2 className="partners-title">Գործընկերներ</h2>
    <div className="partners-container">
  
      <div className="partners-logos">
        {partners.map((partner, index) => (
          <a 
            key={index} 
            href={partner.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="partner-link"
          >
            <img
              src={partner.src}
              alt={partner.alt}
              className="partner-logo"
            />
          </a>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Partners;

