import React, { useEffect } from "react";
import "./upcomingEvents.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../../redux/actions/eventsActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Card } from "antd";
import { Link } from "react-router-dom";
import noImage from "../../../assets/No_Image_Available.jpg";

const UpcomingEvents = () => {
  const dispatch = useDispatch();
  const { top_events, loading, error } = useSelector((state) => state.events);
  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);
  if (loading)
    return (
      <p style={{ textAlign: "center", margin: "200px" }}>Բեռնում է ...</p>
    );

  return (
    <div className="main-container">
      <Link to="/events" style={{ textDecoration: "none" }}>
        <h2 className="titleEvents">Առաջիկա հանդիպումներ</h2>
      </Link>
      {top_events.length > 0 && (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          spaceBetween={30}
          slidesPerView={4}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },

            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            200: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {top_events.map((item) => (
            <SwiperSlide key={item.id}>
              <Link
                to={`/events/${item.id}`}
                style={{ textDecoration: "none" }}
              >
                <Card
                  className="cardUpEvents"
                  cover={
                    item.images && item.images.length > 0 ? (
                      <img
                        className="image"
                        alt={item.title}
                        src={item.images[0].file_path}
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <img
                        className="image"
                        alt="No Image Available"
                        src={noImage}
                        style={{ objectFit: "cover" }}
                      />
                    )
                  }
                >
                  <Card.Meta
                    description={
                      <div className="contentEvents">
                        <p className="eventstitle">{item.title}</p>

                        <p className="dateEvent">
                          <span>Սկիզբ։</span>{" "}
                          {item.start_date.split(":").slice(0, 2).join(":")}
                        </p>

                        {item.start_date.split(":").slice(0, 2).join(":") !==
                          item.end_date.split(":").slice(0, 2).join(":") && (
                          <p className="dateEvent">
                            <span>Ավարտ։</span>{" "}
                            {item.end_date.split(":").slice(0, 2).join(":")}
                          </p>
                        )}

                        <p className="dateEvent">
                          <span>Անցկացման վայրը։</span> {item.place_country},
                          {item.place_city},{item.place_address}
                        </p>

                        {/* <Link>
                        <button className="buttonUpEvents">
                          Իմանալ ավելին
                        </button>
                      </Link> */}
                        {/* <p className="descEvents">{item.content}</p> */}
                      </div>
                    }
                  />
                </Card>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default UpcomingEvents;
