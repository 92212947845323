import React, { useEffect } from "react";
import "./usersHome.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../redux/actions/usersActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import noImagePerson from "../../../assets/noImagePerson.png";
const UsersHome = () => {
  const dispatch = useDispatch();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { topUsers, loading, error } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  if (loading)
    return (
      <p style={{ textAlign: "center", margin: "200px" }}>Բեռնում է ...</p>
    );

  return (
    <div className="usersContainer">
      <Link to="/judo-users" style={{ textDecoration: "none" }}>
        <h1 className="titleUsers">Լավագույնները</h1>
      </Link>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        spaceBetween={20}
        slidesPerView={3}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },

          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          200: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {topUsers && topUsers.length > 0
          ? topUsers.map((topUser) => (
              <SwiperSlide key={topUser.id}>
                <div className="usersCard topUserCard">
                  <Link
                    to={`/judo-users/${topUser.id}`}
                    key={topUser.id}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="usersImg"
                      // src={`${API_BASE_URL}/storage/${topUser?.image?.file_path}`}
                      src={
                        topUser?.image?.file_path
                          ? `${API_BASE_URL}/storage/${topUser?.image?.file_path}`
                          : noImagePerson
                      }
                      alt={topUser.firstname}
                    />
                    <div className="userContent">
                      <p>
                        {topUser.firstname} {topUser.surname}
                      </p>
                      <p>{topUser.role_type}</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))
          : ""}
      </Swiper>
    </div>
  );
};
export default UsersHome;
