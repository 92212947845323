import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEventsById } from "../../../redux/actions/eventsActions";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination as SwiperPagination,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./eventsDetails.css";

const EventsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { eventsDetail, loading, error } = useSelector((state) => state.events);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchEventsById(id));
  }, [dispatch, id]);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (loading)
    return <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>;
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="eventsDetails-container">
      {eventsDetail && (
        <>
          <div className="eventsDetails-header">
            {/* First Image */}
            {eventsDetail.images.length > 0 && (
              <img
                className="mainImage"
                src={eventsDetail.images[0].file_path}
                alt={eventsDetail.title}
                onClick={() => handleImageClick(0)}
              />
            )}

            {/* Title and Date */}
            <div className="eventsDetails-info">
              <h1 className="eventsDetTitle">{eventsDetail.title}</h1>
              <p className="dateEventDet">
                <span>Սկիզբ։</span>{" "}
                {eventsDetail.start_date.split(":").slice(0, 2).join(":")}
              </p>

              {eventsDetail.start_date.split(":").slice(0, 2).join(":") !==
                eventsDetail.end_date.split(":").slice(0, 2).join(":") && (
                <p className="dateEventDet">
                  <span>Ավարտ։</span>{" "}
                  {eventsDetail.end_date.split(":").slice(0, 2).join(":")}
                </p>
              )}

              <p className="dateEventDet">
                <span>Անցկացման վայրը։</span> {eventsDetail.place_country},{" "}
                {eventsDetail.place_city}, {eventsDetail.place_address}
              </p>

              {eventsDetail.files && eventsDetail.files.length > 0 && (
                <p className="dateEventDet">
                  <span>Ներբեռնել </span>
                  {eventsDetail.files.map((file, index) => (
                    <button
                      key={index}
                      onClick={() => window.open(file.file_path, "_blank")}
                      className="file-button"
                    >
                      Ֆայլ {index + 1}
                    </button>
                  ))}
                </p>
              )}
            </div>
          </div>

          {/* Content */}
          <div className="eventContent">
            <p dangerouslySetInnerHTML={{ __html: eventsDetail.content }} />
          </div>

          {/* Rest of the Images */}
          {eventsDetail.images.length > 1 && (
            <div className="event-images">
              <Swiper
                modules={[Navigation, SwiperPagination, Autoplay]}
                spaceBetween={20}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  400: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {eventsDetail.images.slice(1).map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image.file_path}
                      alt={`Event Image ${index + 1}`}
                      className="event-image"
                      onClick={() => handleImageClick(index + 1)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          {/* Modal for Image Zoom */}
          {modalOpen && (
            <div className="modal-overlay" onClick={closeModal}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <Swiper
                  initialSlide={activeImageIndex}
                  breakpoints={{
                    1200: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    600: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    400: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    200: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {eventsDetail.images.map((image, index) => (
                    <SwiperSlide
                      key={index}
                      className="swiper-slide-image"
                      style={{
                        backgroundImage: `url(${image.file_path})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                    ></SwiperSlide>
                  ))}
                </Swiper>
                <span className="close-modal" onClick={closeModal}>
                  &times;
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EventsDetails;
