import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchUsersById } from "../../../redux/actions/usersActions";
import { Rate, Modal, message } from "antd";
import IPService from "../../../services/ipService";
import { rateUserById } from "../../../redux/actions/rateActions";
import noImagePerson from "../../../assets/noImagePerson.png";

// Dynamically load CSS when component mounts
const loadCSS = () => import("./usersDetails.css");

const UsersDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { usersDetail, loading, error } = useSelector((state) => state.users);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // State to track if user has rated and the current rating
  const [hasRated, setHasRated] = useState(false);
  const [rating, setRating] = useState(0);
  const [averageRating, setAverageRating] = useState(
    usersDetail?.average_rating || 0
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  // State to control number of visible users
  const [visibleUsersCount, setVisibleUsersCount] = useState(10);

  useEffect(() => {
    dispatch(fetchUsersById(id));
    loadCSS();

    // Check if user has already rated
    IPService.getIPAddress().then((ipAddress) => {
      const ratedUsers = JSON.parse(localStorage.getItem("ratedUsers")) || {};
      if (ratedUsers[ipAddress]?.includes(id)) {
        setHasRated(true);
      }
    });
  }, [dispatch, id]);

  const handleRate = async (value) => {
    const ipAddress = await IPService.getIPAddress();
    if (!ipAddress) {
      message.error("Unable to fetch IP address.");
      return;
    }

    // Check if user has already rated this user
    const ratedUsers = JSON.parse(localStorage.getItem("ratedUsers")) || {};
    if (ratedUsers[ipAddress]?.includes(id)) {
      message.warning("You have already rated this user.");
      return;
    }

    setRating(value);

    // Dispatch the rate action
    dispatch(rateUserById(id, value, ipAddress));

    // Update local storage to mark the user as rated
    ratedUsers[ipAddress] = [...(ratedUsers[ipAddress] || []), id];
    localStorage.setItem("ratedUsers", JSON.stringify(ratedUsers));
    setIsModalVisible(true);
    setHasRated(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  // Function to show more users
  const showMoreUsers = () => {
    setVisibleUsersCount((prevCount) => prevCount + 10); // Increment by 10
  };

  // Handle loading and error cases
  if (loading)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է ...</p>
    );
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="usersDetails-container">
      {usersDetail ? (
        <>
          <div className="usersDetails-header">
            <img
              className="usersImage"
              src={usersDetail.image_url}
              alt={usersDetail.firstname}
            />
            <div className="usersDetails-info">
              <h1 className="usersDetailsName">
                {usersDetail.firstname} {usersDetail.surname}{" "}
                {usersDetail.patronymic}
              </h1>
              {usersDetail.date_of_birth && (
                <p className="usersWeight">
                  <span>Ծնվել է ՝</span>
                  {usersDetail.date_of_birth?.split("T")[0]}{" "}
                </p>
              )}
              {usersDetail.weight && (
                <p className="usersWeight">
                  <span>Քաշ ՝</span> {usersDetail.weight} կգ{" "}
                </p>
              )}
              {usersDetail.sex && (
                <p className="usersWeight">
                  <span>Սեռ ՝</span> {usersDetail.sex}{" "}
                </p>
              )}
              {usersDetail.email && (
                <p className="usersWeight">
                  <span>Էլ. փոստ ՝</span> {usersDetail.email}{" "}
                </p>
              )}
              {usersDetail.role_type && (
                <p className="usersWeight">
                  <span>Դեր ՝</span> {usersDetail.role_type}{" "}
                </p>
              )}
              {usersDetail.trainer && (
                <p className="usersWeight">
                  <span>Մարզիչ ՝</span>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/judo-users/${usersDetail.trainer.id}`}
                  >
                    {usersDetail.trainer.firstname}{" "}
                    {usersDetail.trainer.surname}
                  </Link>
                </p>
              )}

              {/* Rating System */}
              <div className="rating-section">
                {!hasRated ? (
                  <>
                    <p className="usersWeight">
                      <span style={{ color: "red" }}>Գնահատել</span>
                    </p>
                    <Rate onChange={handleRate} value={rating} />
                  </>
                ) : (
                  <div>
                    <p className="usersWeight">
                      <span>Վարկանիշ՝ </span> {usersDetail.average_rate}
                    </p>
                    <Rate
                      disabled
                      value={averageRating}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
              >
                <p>Շնորհակալություն գնահատելու համար!</p>
              </Modal>
            </div>
          </div>

          <div className="usersDetails-content">
            {usersDetail.content_info && (
              <p className="userDetailsContent">
                <span>Կենսագրություն</span>
                <p
                  dangerouslySetInnerHTML={{ __html: usersDetail.content_info }}
                />
              </p>
            )}
          </div>

          <div className="usersListSection">
            {usersDetail?.trainees && usersDetail.trainees.length > 0 ? (
              <>
                <p className="usersTrainersDetails">Մարզվողներ</p>
                <div className="usersList">
                  {usersDetail.trainees
                    .slice(0, visibleUsersCount)
                    .map((user) => (
                      <div key={user.id} className="usersCard">
                        <Link
                          to={`/judo-users/${user.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            className="usersIamge"
                            src={
                              user?.image?.file_path
                                ? `${API_BASE_URL}/storage/${user.image?.file_path}`
                                : noImagePerson
                            }
                            alt={user.firstname}
                          />
                          <div className="usersContent">
                            <p>
                              {user.firstname} {user.surname}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
                {usersDetail?.trainees &&
                  usersDetail?.trainees.length > 0 &&
                  visibleUsersCount < usersDetail.trainees.length && (
                    <div>
                      <button className="seeMoreButton" onClick={showMoreUsers}>
                        Ավելին
                      </button>
                    </div>
                  )}
              </>
            ) : usersDetail.role_type === "Մարզիչ" ? (
              <p style={{ height: "100vh" }}>Ոչինչ չի գտնվել։</p>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default UsersDetails;
