import React, { useEffect, useState } from 'react';
import SliderComponent from '../Slider/slider';
import { Link } from 'react-router-dom';
import UsersHome from './UsersHome/index';
import Partners from './Partners/partners';
import UpcomingEvents from './UpcomingEvents/upcomingEvents';
import President from './PresidentSection/index';

const Home = () => {
  return (<>
    <Link to="/"></Link>
  <SliderComponent/>
  <UpcomingEvents/>
  <UsersHome/>
  <President/>
  <Partners/>
  </>
  );
};

export default Home;