import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section about">
        <h3>Մեր մասին</h3>
        <p>
          Հայաստանի ձյուդոյի ֆեդերացիան հանդիսանում է Հայաստանում ձյուդոյի
          կարգավորող մարմինը
        </p>
        <div className="social-links">
          <a
            href="https://www.facebook.com/judofederationarmenia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook"></i>
          </a>
          <a
            href="https://x.com/judoarmenian?mx=2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/armenianjudofederation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram"></i>
          </a>
        </div>
      </div>

      <div className="footer-section links">
        <h3>Օգտակար հղումներ</h3>
        <ul>
          <li>
            <a href="/">Գլխավոր</a>
          </li>
          <li>
            <a href="/news">Նորություններ</a>
          </li>

          <li>
            <a href="/events">Օրացույց</a>
          </li>
          <li>
            <a href="/members">Անդամներ</a>
          </li>
          <li>
            <a href="/results">Արդյունքներ</a>
          </li>
          <li>
            <a href="/about">Մեր մասին</a>
          </li>
        </ul>
      </div>

      <div className="footer-section contact">
        <h3>Կապ մեզ հետ</h3>
        <ul>
          <li>
            <i className="fa fa-phone"></i> +374 (91) 40 92 29
          </li>
          <li>
            <i className="fa fa-envelope"></i>info@judo.am
          </li>
          <li>
            <i className="fa fa-map-marker"></i> Երևան, Հայաստան
          </li>
        </ul>
      </div>

      <div className="footer-bottom">
        <p>
          Created By{" "}
          <a
            href="https://am.linkedin.com/in/davit-prazyan"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            D. Prazyan
          </a>{" "}
          | All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
