import axios from "axios";
export const FETCH_PLACE_REQUEST = "FETCH_PLACE_REQUEST";
export const FETCH_PLACE_SUCCESS = "FETCH_PLACE_SUCCESS";
export const FETCH_PLACE_FAILURE = "FETCH_PLACE_FAILURE";
export const FETCH_PLACE_DETAILS_REQUEST = "FETCH_PLACE_DETAILS_REQUEST";
export const FETCH_PLACE_DETAILS_SUCCESS = "FETCH_PLACE_DETAILS_SUCCESS";
export const FETCH_PLACE_DETAILS_FAILURE = "FETCH_PLACE_DETAILS_FAILURE";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchPlaceRequest = () => ({ type: FETCH_PLACE_REQUEST });
const fetchPlaceSuccess = (place) => ({
  type: FETCH_PLACE_SUCCESS,
  payload: place,
});
const fetchPlaceFailure = (error) => ({
  type: FETCH_PLACE_FAILURE,
  payload: error,
});

export const fetchPlace = (category, page = 1) => {
  return async (dispatch) => {
    dispatch(fetchPlaceRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/places`, {
        params: {
          category: category,
          sort: "created_at",
          direction: "desc",
          page: page,
        },
      });
      const place = response.data.data || [];

      dispatch(fetchPlaceSuccess(place));
    } catch (error) {
      dispatch(fetchPlaceFailure(error.message));
    }
  };
};

const fetchPlaceDetailsRequest = () => ({ type: FETCH_PLACE_DETAILS_REQUEST });
const fetchPlaceDetailsSuccess = (placeDetail) => ({
  type: FETCH_PLACE_DETAILS_SUCCESS,
  payload: placeDetail,
});
const fetchPlaceDetailsFailure = (error) => ({
  type: FETCH_PLACE_DETAILS_FAILURE,
  payload: error,
});

export const fetchPlaceById = (id) => {
  return async (dispatch) => {
    dispatch(fetchPlaceDetailsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/places/${id}`);
      const placeDetail = response.data;

      dispatch(fetchPlaceDetailsSuccess(placeDetail));
    } catch (error) {
      dispatch(fetchPlaceDetailsFailure(error.message));
    }
  };
};
