import {
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAILURE,
} from "../actions/emailActions";

const initialState = {
  emailInfo: null,
  loading: false,
  error: null,
};

const emailSendReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_SEND_REQUEST:
      return {
        ...state,
        loading: true,
        emailInfo: null,
        error: null,
      };

    case EMAIL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        emailInfo: action.payload,
        error: null,
      };

    case EMAIL_SEND_FAILURE:
      return {
        ...state,
        loading: false,
        emailInfo: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default emailSendReducer;
