import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import News from "../src/components/News/news";
import Navbar from "../src/components/Navbar/navbar";
import Footer from "../src/components/Footer/index";
import Home from "../src/components/Home/home";
import NewsDetails from "./components/News/NewsDetail/newsDetail";
import Events from "../src/components/Events/index";
import EventsDetails from "./components/Events/EventsDetails/eventsDetails";
import Video from "./components/Video/index";
import VideoDetails from "./components/Video/VideoDetails/index";
import Users from "./components/Users/index";
import UsersDetails from "./components/Users/UsersDetails/index";
import About from "./components/About/about";
import Federation from "./components/About/Federation/federation";
import Management from "./components/About/Management/management";
import History from "./components/About/History/history";
import IPService from "./services/ipService";
import Places from "./components/Places/places";
import PlacesDetails from "./components/Places/PlacesDetails/placesDetails";

const App = () => {
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchIP = async () => {
      const ipAddress = await IPService.getIPAddress(); // Call the service
      if (ipAddress) {
        setIP(ipAddress);
      }
    };

    fetchIP();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/news/:id" element={<NewsDetails />} />
        <Route path="/news/category/:category" element={<News />} />
        <Route path="/news" element={<News />} />
        <Route path="/events/:id" element={<EventsDetails />} />
        <Route path="/events/category/:category" element={<Events />} />
        <Route path="/events" element={<Events />} />
        <Route path="/" element={<Home />} />
        <Route path="/results/:id" element={<VideoDetails />} />
        <Route path="/results" element={<Video />} />
        <Route path="/judo-users/:id" element={<UsersDetails />} />
        <Route path="/judo-users" element={<Users />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/federation" element={<Federation />} />
        <Route path="/about/history" element={<History />} />
        <Route path="/about/management" element={<Management />} />
        <Route path="/place/:id" element={<PlacesDetails />} />
        <Route path="/place/category/:category" element={<Places />} />
        {/* <Route path="/place/:id" element={<PlacesDetails />} /> */}
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
