import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../src/redux/store';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
<React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
    <App />
  </Provider>
</BrowserRouter>
</React.StrictMode>
)