import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";
export const FETCH_EVENTS_DETAILS_REQUEST = "FETCH_EVENTS_DETAILS_REQUEST";
export const FETCH_EVENTS_DETAILS_SUCCESS = "FETCH_EVENTS_DETAILS_SUCCESS";
export const FETCH_EVENTS_DETAILS_FAILURE = "FETCH_EVENTS_DETAILS_FAILURE";

const fetchEventsRequest = () => ({ type: FETCH_EVENTS_REQUEST });
const fetchEventsSuccess = (events, top_events) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: { events, top_events },
});
const fetchEventsFailure = (error) => ({
  type: FETCH_EVENTS_FAILURE,
  payload: error,
});

export const fetchEvents = (
  category,
  page = 1,
  start_date = null,
  end_date = null,
  search ="",
  sex = ""
) => {
  return async (dispatch) => {
    dispatch(fetchEventsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/events`, {
        params: {
          category: category,
          sort: "created_at",
          direction: "desc",
          page: page,
          start_date: start_date, 
          end_date: end_date,
          search,
          sex
        },
      });
      const events = response.data.events ? response.data.events : [];
      const top_events = response.data.top_events
        ? response.data.top_events
        : [];

      dispatch(fetchEventsSuccess(events, top_events));
    } catch (error) {
      dispatch(fetchEventsFailure(error.message));
    }
  };
};

const fetchEventsDetailsRequest = () => ({
  type: FETCH_EVENTS_DETAILS_REQUEST,
});
const fetchEventsDetailsSuccess = (eventsDetail) => ({
  type: FETCH_EVENTS_DETAILS_SUCCESS,
  payload: eventsDetail,
});
const fetchEventsDetailsFailure = (error) => ({
  type: FETCH_EVENTS_DETAILS_FAILURE,
  payload: error,
});

export const fetchEventsById = (id) => {
  return async (dispatch) => {
    dispatch(fetchEventsDetailsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/events/${id}`);
      const eventsDetail = response.data;
      dispatch(fetchEventsDetailsSuccess(eventsDetail));
    } catch (error) {
      dispatch(fetchEventsDetailsFailure(error.message));
    }
  };
};
