// src/services/ipService.js
import axios from "axios";
import Cookies from "js-cookie";

const IPService = {
    getIPAddress: async () => {
        // Check if IP is already in cookies
        const ipFromCookies = Cookies.get("userIP");

        if (ipFromCookies) {
            return ipFromCookies; // If IP is in cookies, return it
        }

        try {
            // If not, fetch IP from API and store in cookies
            const response = await axios.get("https://api.ipify.org?format=json");
            const ipAddress = response.data.ip;

            // Store IP in cookies for 1 year
            Cookies.set("userIP", ipAddress, { expires: 365 });

            return ipAddress;
        } catch (error) {
            console.error("Error fetching IP address: ", error);
            return null;
        }
    },
};

export default IPService;
