import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../../assets/mainLogo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navRef = useRef(null);

  const handleClick = () => {
    setActive(!active);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setActive(false); // Close the menu when clicking outside of it
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="menu-icon" onClick={handleClick}>
        <i className={active ? "fa fas fa-times" : "fa fas fa-bars"}></i>
      </div>
      <ul className={active ? "navbar-menu active" : "navbar-menu"}>
        <li className="navbar-item">
          <button>
            <Link to="/">Գլխավոր</Link>
          </button>
        </li>
        <div className="news__head_navbar">
          <div className="navbar-item dropdown" role="menu">
            <button>
              <Link to="/news">
                Նորություններ
                <span className="arrow"></span>
              </Link>
            </button>
            <div className="dropdown-menu">
              <div role="menuitem">
                <Link to="news/category/workouts">Մարզումներ</Link>
              </div>
              <div role="menuitem">
                <Link to="news/category/gatherings">Հավաքներ</Link>
              </div>
              <div role="menuitem">
                <Link to="news/category/international">Միջազգային</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="news__head_navbar">
          <div className="navbar-item dropdown" role="menu">
            <button>
              <Link to="/events">
                Օրացույց <span className="arrow"></span>
              </Link>
            </button>
            <div className="dropdown-menu">
              <div role="menuitem">
                <Link to="events/category/teenagers">Պատանիներ</Link>
              </div>
              <div role="menuitem">
                <Link to="events/category/young">Երիտասարդներ</Link>
              </div>
              <div role="menuitem">
                <Link to="events/category/adults">Մեծահասակներ</Link>
              </div>
              <div role="menuitem">
                <Link to="events/category/international">Միջազգային</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="news__head_navbar">
          <div className="navbar-item dropdown" role="menu">
            <button>
              <Link to="/judo-users">
                Անդամներ <span className="arrow"></span>
              </Link>
            </button>
            <div className="dropdown-menu">
              <div role="menuitem">
                <Link to="/place/category/schools">Դպրոցներ</Link>
              </div>
              <div role="menuitem">
                <Link to="/place/category/clubs">Ակումբներ</Link>
              </div>
              <div role="menuitem">
                <Link to="/place/category/federation">Մարզային Ֆեդերացիա</Link>
              </div>
            </div>
          </div>
        </div>
        <li className="navbar-item">
          <button>
            <Link to="/results">Արդյունքներ</Link>
          </button>
        </li>

        <div className="news__head_navbar">
          <div className="navbar-item dropdown" role="menu">
            <button>
              <Link to="#">
                Մեր մասին <span className="arrow"></span>
              </Link>
            </button>
            <div className="dropdown-menu">
              <div role="menuitem">
                <Link to="about/federation">Ֆեդերացիայի մասին</Link>
              </div>
              <div role="menuitem">
                <Link to="about/history">Պատմություն և կուլտուրա</Link>
              </div>
              <div role="menuitem">
                <Link to="about/management">Կազմը</Link>
              </div>
            </div>
          </div>
        </div>

        {/* <li className="navbar-item">
          <button>
            <Link to="/ranking">Վարկանիշ</Link>
          </button>
        </li> */}
      </ul>
      <div className="navbar-icons">
        <Link to="/login" className="navbar-icon">
          <FontAwesomeIcon icon={faUser} />
        </Link>
        <Link to="/cart" className="navbar-icon">
          <FontAwesomeIcon icon={faShoppingCart} />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
