import axios from "axios";
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const FETCH_USERS_DETAILS_REQUEST = "FETCH_USERS_DETAILS_REQUEST";
export const FETCH_USERS_DETAILS_SUCCESS = "FETCH_USERS_DETAILS_SUCCESS";
export const FETCH_USERS_DETAILS_FAILURE = "FETCH_USERS_DETAILS_FAILURE";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchUsersRequest = () => ({ type: FETCH_USERS_REQUEST });
const fetchUsersSuccess = (users, topUsers) => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users, topUsers },
});
const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

export const fetchUsers = (category, page = 1, search, sex, role_type) => {
  return async (dispatch) => {
    dispatch(fetchUsersRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/judo-users`, {
        params: {
          category: category,
          sort: "created_at",
          direction: "desc",
          page: page,
          search: search,
          sex: sex,
          role_type: role_type,
        },
      });
      // const users = response.data.users ? response.data.users : [];
      const users = response.data.judoUsers.data
        ? response.data.judoUsers
        : [];
      const topUsers = response.data.topUsers ? response.data.topUsers : [];
      dispatch(fetchUsersSuccess(users, topUsers));
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
    }
  };
};

const fetchUsersDetailsRequest = () => ({ type: FETCH_USERS_DETAILS_REQUEST });
const fetchUsersDetailsSuccess = (usersDetail) => ({
  type: FETCH_USERS_DETAILS_SUCCESS,
  payload: usersDetail,
});
const fetchUsersDetailsFailure = (error) => ({
  type: FETCH_USERS_DETAILS_FAILURE,
  payload: error,
});

export const fetchUsersById = (id) => {
  return async (dispatch) => {
    dispatch(fetchUsersDetailsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/judo-users/${id}`);
      const usersDetail = response.data;

      dispatch(fetchUsersDetailsSuccess(usersDetail));
    } catch (error) {
      dispatch(fetchUsersDetailsFailure(error.message));
    }
  };
};
