import React, { useEffect, useState } from "react";
import "./about.css";

const About = () => {
  return (
    <div className="aboutContainer">
      <strong>Հայաստանի ձյուդոյի ֆեդերացիա </strong>
      Հայաստանի ձյուդոյի ֆեդերացիան հանդիսանում է Հայաստանում ձյուդոյի
      կարգավորող մարմինը: Ֆեդերացիայի կենտրոնակայանը գտնվում է Երևանում:
      Հայաստանի ձյուդոյի ֆեդերացիան առաջնորդվում է միջազգային ֆեդերացիայի
      կանոնակարգով և Հայաստանի Հանրապետության օրենքներով։ 1972թ. ԽՍՀՄ
      սպորտպետկոմի հրահանգով միութենական բոլոր հանրապետություններում ստեղծվում
      են մարզաձևի ֆեդերացիաներ։ Հայաստանում կազմակերպության նախագահ է ընտրվել
      ըմբշամարտ մարզաձևի մասնագետ, վաստակավոր մարզիչ, ֆիզիկական կուլտուրայի
      հայկական պետական ինստիտուտի ամբիոնի վարիչ պրոֆեսոր Կառլեն Աթոյանը։
      Ֆեդերացիան հիմնադրվել է 1992 թվականին, և ներկայիս նախագահը Վարդան
      Ոսկանյանն է: Ֆեդերացիան հանդիսանում է Միջազգային ձյուդոյի ֆեդերացիայի և
      Եվրոպական ձյուդոյի միության լիիրավ անդամ: Հայ ձյուդոիստները մասնակցում են
      ձյուդոյի միջազգային մրցաշարերին՝ Եվրոպայի և աշխարհի առաջնություններին,
      ինչպես նաև Օլիմպիական խաղերին։
    </div>
  );
};

export default About;
