import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchNews } from "../../redux/actions/newsActions";
import { Card, Pagination, DatePicker, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./news.css";
import noImage from "../../assets/No_Image_Available.jpg";

const { Search } = Input;

const News = () => {
  const dispatch = useDispatch();
  const { news, loading, error } = useSelector((state) => state.news);
  const location = useLocation();
  const category = location.pathname.split("/")[3] || "";
  const [selectedDate, setSelectedDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    dispatch(fetchNews(category, currentPage, selectedDate, ""));
  }, [dispatch, category, currentPage, selectedDate]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    dispatch(fetchNews(category, currentPage, selectedDate, value));
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format("DD-MM-YYYY") : null;
    setSelectedDate(formattedDate);
    dispatch(fetchNews(category, currentPage, formattedDate, searchTerm));
  };

  const handlePageChange = (perPage) => {
    setCurrentPage(perPage);
    dispatch(fetchNews(category, perPage, selectedDate, searchTerm));
  };

  return (
    <>
      {/* DatePicker and Search bar - always visible */}
      <div className="datepicker-container">
        <Search
          className="input"
          placeholder="Որոնել "
          enterButton={<SearchOutlined />}
          value={searchTerm}
          onSearch={handleSearchChange}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <DatePicker
          className="datePicker"
          placeholder="Որոնել ըստ ամսաթվի"
          onChange={handleDateChange}
        />
      </div>

      {/* news-container with conditional rendering for loading and error */}
      <div className="news-container">
        {loading ? (
          <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>
        ) : error ? (
          <p style={{ textAlign: "center", height: "100vh" }}>
            Ներողություն, առկա է տեխնիկական խնդիր
          </p>
        ) : news.data && news.data.length > 0 ? (
          news.data.map((item) => (
            <Link
              to={`/news/${item.id}`}
              key={item.id}
              style={{ textDecoration: "none" }}
            >
              <Card
                className="card"
                key={item.id}
                cover={
                  item.images && item.images.length > 0 ? (
                    <img
                      className="image"
                      alt={item.title}
                      src={`${API_BASE_URL}/storage/${item.images[0].file_path}`}
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <img
                      className="image"
                      alt="No Image Available"
                      src={noImage}
                      style={{ objectFit: "cover" }}
                    />
                  )
                }
              >
                <Card.Meta
                  description={
                    <div className="content">
                      <p className="date">{item.created_at.split("T")[0]}</p>
                      <p className="newstitle">{item.title}</p>
                      <p
                        className="desc"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p>
                    </div>
                  }
                />
              </Card>
            </Link>
          ))
        ) : (
          <p>Նորություններ չկան</p>
        )}
      </div>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={news.total || 0}
        onChange={handlePageChange}
        className="pagination"
      />
    </>
  );
};

export default News;
