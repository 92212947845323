import React from "react";
import kanoJudo from "../../../assets/kanoJudo.jpg";
import kawaishiJudo from "../../../assets/kawaishi.jpg";
import "./history.css";

const History = () => {
  return (
    <div className="historyContainer">
      <strong>Միջազգային պատմություն և կուլտուրա</strong>{" "}
      <div className="contentSection">
        <div className="imageSection">
          <img src={kanoJudo} alt="Jigoro Kano" className="judoImage" />
          <p className="personName">
            Ջիգորո Կանո <br /> (1860-1938)
          </p>
        </div>
        <div className="textSection">
          <p>
            1882 թվականի մայիսին Ջիգորո Կանոն, ոգեշնչված ավանդական մարտարվեստի
            ձևերով, ստեղծեց ֆիզիկական, ինտելեկտուալ և բարոյական դաստիարակության
            մեթոդ, որը նա անվանեց ձյուդո: Սկզբում դիտարկվող որպես
            ինքնապաշտպանության համակարգ, ձյուդոն աստիճանաբար սկսեց ճանաչվել իր
            կրթական արժեքի, մարմնի և բնավորության զարգացման համար ունեցած օգուտի
            համար: Ձյուդոն դարձավ օլիմպիական մարզաձև Տոկիոյի խաղերում 1964
            թվականին և այդպիսով դարձավ համընդհանուր: Այսօր Միջազգային ձյուդոյի
            ֆեդերացիան միավորում է ավելի քան 200 ազգային ֆեդերացիաներ և 5
            մայրցամաքային միություններ:
          </p>
        </div>
      </div>
      <strong>Կանոյի ձյուդոն. Փիլիսոփայություն և կրթություն </strong>
      <p>
        Փոքր և դասընկերների կողմից ճնշված լինելով՝ Ջիգորո Կանոն ցանկանում էր
        ամրացնել իր մարմինը և սովորել ինքնապաշտպանվել: Նա ուսումնասիրեց
        ջիու-ջիցու, բայց շուտով հասկացավ, որ պայքարը կենտրոնացած է հզոր մարմնի
        վրա և ոչ թե մարդկանց հոգևոր և կրթական չափումների վրա: Կանոն հեռացրեց
        ջիու-ջիցուի վտանգավոր տեխնիկաները՝ բարելավելով ընկնելու արվեստը: Նա
        պարտադրեց, որ երկու մարտիկները բռնեն միմյանց և ստեղծեց իր մեթոդը՝
        սինթեզելով պաշտպանության ամենահին ձևերը: 1882 թվականին նա բացեց դոջո,
        որը կոչեց Կոդոկան, «Վայրը, որտեղ ուսումնասիրվում է ճանապարհը»: 1922
        թվականին Կանոն ստեղծեց Կոդոկանի Մշակութային Ընկերությունը: Նա գրում է
        երկու ասույթներ, որոնք սահմանում են իր մեթոդի փիլիսոփայությունը՝
        Սեյրյոկու զենյո («Լավագույն էներգիայի օգտագործումը») և Ջիտա Կյոեյ
        («Փոխադարձ բարօրություն և օգուտ»): 1909 թվականին Կանոն նշանակվեց
        Միջազգային օլիմպիական կոմիտեում: Նա դարձավ ՄՕԿ-ի առաջին ասիացի անդամը և
        նվիրեց իր կյանքը կրթության զարգացմանը ձյուդոյի և սպորտի միջոցով
        Ճապոնիայում և ամբողջ աշխարհում:
      </p>
      <strong> Կանոյի ճանապարհորդությունները</strong>
      <p>
        1889 Փարիզ, Բրյուսել, Ամստերդամ, Բեռլին <br />
        1912 5-րդ Օլիմպիականխաղեր Ստոկհոլմում, Վիեննա, Փարիզ, Լոնդոն <br />
        1920 7-րդ Օլիմպիական խաղեր Անտվերպենում, Փարիզ, Լոնդոն <br />
        1928 9-րդ Օլիմպիական խաղեր Ամստերդամում, Բեռլին, Փարիզ, Հռոմ, Չինաստան{" "}
        <br />
        1932 10-րդ Օլիմպիական խաղեր Լոս Անջելեսում, Վանկուվեր, Սիեթլ, Հավայան
        կղզիներ <br />
        1933 Մոսկվա, Շտուտգարտ, Բեռլին, Լոնդոն, Փարիզ, Մադրիդ <br />
        1934 Մոսկվա, Վարշավա, Վիեննա, Բելգրադ, Փարիզ, Լոնդոն, Նեապոլ, Սինգապուր,
        Հոնկոնգ, Շանհայ <br />
        1936 11-րդ Օլիմպիական խաղեր Բեռլինում, Սիեթլ, Վանկուվեր, Նյու Յորք,
        Բուխարեստ, Փարիզ, Լոնդոն <br />
        1938 Սինգապուր, Վանկուվեր, Կահիրե
      </p>
      <strong>Ձյուդոն աշխարհում </strong>
      <p>
        Կանոյի և նրա աշակերտների ջանքերի շնորհիվ Կոդոկանի ձյուդոն զարգացավ
        ճապոնական էմիգրանտ համայնքներում (ԱՄՆ, Բրազիլիա, Կանադա), ինչպես նաև
        Գերմանիայում, Բրիտանիայում և բրիտանական ազդեցության տակ գտնվող
        երկրներում: Ֆրանսիայում, 1940 թվականից սկսած, Կանոյի մեթոդի արևմտյան
        տարբերակը մշակվեց պրոֆեսոր Կավաիշիի կողմից, ինժեներ Մոշե Ֆելդենկրայսի
        օգնությամբ: Կավաիշիի մեթոդը մեծ հաջողություն է վայելում Եվրոպայում և
        ֆրանսիական ազդեցության տակ գտնվող երկրներում:
      </p>
      <strong>Կավաիշիի մեթոդը </strong>
      <div className="kawaishiSection">
        <p>
          Գոտու գույնը մարմնավորում է ձյուդոիստի վարպետությունը: Սկսնակները
          հաջորդաբար կրում են սպիտակ գոտի, դեղին, նարնջագույն, կանաչ, կապույտ և
          շագանակագույն գոտիներ, նախքան հատուկ քննություն (տեխնիկական և
          մրցակցային) հանձնելը՝ սև գոտի կամ դան (1-ից 10-րդ դան) ստանալու համար:
          Գոտիների գունային համակարգը մշակվել է Անգլիայում 1920-ականների կեսերին
          և մեծ ժողովրդականություն է ձեռք բերել Կավաիշիի մեթոդի շնորհիվ:{" "}
        </p>
        <div className="imageSection">
          <img src={kawaishiJudo} alt="Kawaishi Judo" className="judoImage" />
        </div>
      </div>
      <strong>Կանանց ձյուդո</strong>
      <p>
        1923 թվականին Ջիգորո Կանոն բացեց Կոդոկանի կանանց բաժինը: Նա կենտրոնացավ
        տեխնիկական ուսումնասիրության և կատայի վրա, բայց չթույլատրեց մրցակցային
        պրակտիկան, որը համարվում էր վտանգավոր ապագա մայրերի առողջության համար:
        Կեյկո Ֆուկուդան, Հաչինոսուկե Ֆուկուդայի թոռնուհին, ով ջիու-ջիցու էր
        սովորեցնում Կանոյին, նվիրեց իր կյանքը կանանց ձյուդոն ամբողջ աշխարհում
        տարածելուն:
      </p>{" "}
      <strong>Ձյուդոն որպես սպորտ </strong>{" "}
      <p>
        Պաշտոնական մարզական միջոցառումները սկսեցին տեղի ունենալ 20-րդ դարի
        սկզբին: Եվ հենց Գերմանիայում, 1932 թվականի օգոստոսի 11-ին, ստեղծվեց
        Եվրոպական ձյուդոյի միությունը: Առաջին Եվրոպական առաջնությունները տեղի
        ունեցան Դրեզդենում 1934 թվականին: Կանոյի մեթոդը ընտրվեց որպես
        ցուցադրական սպորտ 1940 թվականի Տոկիոյի Օլիմպիական խաղերի համար, որոնք
        երբեք չկայացան Երկրորդ համաշխարհային պատերազմի պատճառով: Միայն 1964
        թվականի Օլիմպիական խաղերում ձյուդոն պաշտոնապես մտավ Օլիմպիական ծրագրի
        մեջ:
      </p>
      <strong>
        Եվրոպական ձյուդոյի միություն և Միջազգային ձյուդոյի ֆեդերացիա
      </strong>
      <p>
        Եվրոպական ձյուդոյի միությունը վերակազմավորվեց Երկրորդ համաշխարհային
        պատերազմից հետո: 1951 թվականի հուլիսի 11-ին Լոնդոնում, նրա
        ներկայացուցիչները (Մեծ Բրիտանիա, Ֆրանսիա, Իտալիա, Բելգիա, Նիդերլանդներ,
        Գերմանիա, Ավստրիա և Շվեյցարիա) ընդունեցին Արգենտինայի թեկնածությունը, և
        ստեղծվեց Միջազգային ձյուդոյի ֆեդերացիան: Իտալացի Ալդո Տորտին դարձավ
        ՄԴՖ-ի առաջին նախագահը:
      </p>
      <strong>Միջազգային ձյուդոյի ֆեդերացիայի նախագահները</strong>
      <p>
        1951-1952 Ալդո Տորտի, Իտալիա
        <br />
        1952-1965 Ռիսեյ Կանո, Ճապոնիա
        <br />
        1965-1979 Չարլզ Փալմեր, Մեծ Բրիտանիա
        <br />
        1979-1987 Շիգեյոշի Մացումաե, Ճապոնիա
        <br />
        1987-1989 Սարգիս Կալոգյան, Արգենտինա
        <br />
        1989-1991 Լորի Հարգրեյվ, Նոր Զելանդիա
        <br />
        1991-1995 Լուիս Բագուենա, Իսպանիա
        <br />
        1995-2007 Յոնգ Սունգ Պարկ, Հարավային Կորեա
        <br />
        2007-… Մարիուս Լ. Վիզեր, Ավստրիա
      </p>
      <strong>Օլիմպիական ձյուդո</strong>
      <p>
        Ձյուդոն որպես սպորտ արագ աճեց Երկրորդ համաշխարհային պատերազմից հետո:
        <br />
        1951: Եվրոպական առաջնություններ Փարիզում
        <br />
        1956: Առաջին աշխարհի առաջնություններ Տոկիոյում (Մրցույթ առանց քաշային
        կատեգորիայի)
        <br />
        1961: Երրորդ աշխարհի առաջնություններ Փարիզում (Մրցույթ առանց քաշային
        կատեգորիայի)
        <br /> 1964: Օլիմպիական խաղեր Տոկիոյում (-68 կգ; -80 կգ; +80 կգ; Բաց)
        <br />
        1980: Առաջին կանանց աշխարհի առաջնություններ Նյու Յորքում
        <br />
        1988: Կանանց ձյուդոն ներկայացված է Սեուլի Օլիմպիական խաղերում որպես
        ցուցադրական սպորտ
        <br />
        1992: Բարսելոնայի Օլիմպիական խաղերից սկսած, ինչպես տղամարդկանց, այնպես
        էլ կանանց մրցույթները ներառված են Օլիմպիական ծրագրում:
      </p>
    </div>
  );
};

export default History;
