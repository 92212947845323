import { combineReducers } from "redux";
import newsReducer from "./newsReducer";
import usersReducer from "./usersReducer";
import eventsReducer from "./eventsReducer";
import videoReducer from "./videoReducer";
import emailSendReducer from "./emailSendReducer";
import placeReducer from "./placeReducer";
import organizationReducer from './organizationReducer';
// import familyTreeReducer from "./familyTreeReducer";

const rootReducer = combineReducers({
  news: newsReducer,
  users: usersReducer,
  events: eventsReducer,
  video: videoReducer,
  emailSend: emailSendReducer,
  // FamilyTree: familyTreeReducer,
  place: placeReducer,
  organization: organizationReducer,
});

export default rootReducer;
