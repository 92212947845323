import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';
export const FETCH_NEWS_DETAILS_REQUEST = 'FETCH_NEWS_DETAILS_REQUEST';
export const FETCH_NEWS_DETAILS_SUCCESS = 'FETCH_NEWS_DETAILS_SUCCESS';
export const FETCH_NEWS_DETAILS_FAILURE = 'FETCH_NEWS_DETAILS_FAILURE';
// Combined action creators
const fetchNewsRequest = () => ({ type: FETCH_NEWS_REQUEST });
const fetchNewsSuccess = (news, top_news) => ({
  type: FETCH_NEWS_SUCCESS,
  payload: { news, top_news },
});
const fetchNewsFailure = (error) => ({ type: FETCH_NEWS_FAILURE, payload: error });

export const fetchNews = (category, page = 1, date = null, search) => {
  return async (dispatch) => {
    dispatch(fetchNewsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/news` ,{
        params: {
          category: category,
          sort: 'created_at',
          direction: 'desc',
          page: page,
          date: date,
          search: search
        },
      });
      const news = response.data.news ? response.data.news : [];
      const top_news = response.data.top_news ? response.data.top_news : []

      dispatch(fetchNewsSuccess(news, top_news));
    } catch (error) {
      dispatch(fetchNewsFailure(error.message));
    }
  };
};


const fetchNewsDetailsRequest = () => ({ type: FETCH_NEWS_DETAILS_REQUEST });
const fetchNewsDetailsSuccess = (newsDetail) => ({
  type: FETCH_NEWS_DETAILS_SUCCESS,
  payload: newsDetail,
});
const fetchNewsDetailsFailure = (error) => ({
  type: FETCH_NEWS_DETAILS_FAILURE,
  payload: error,
});

export const fetchNewsById = (id) => {
  return async (dispatch) => {
    dispatch(fetchNewsDetailsRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/news/${id}`);
      const newsDetail = response.data; 
      dispatch(fetchNewsDetailsSuccess(newsDetail));
    } catch (error) {
      dispatch(fetchNewsDetailsFailure(error.message));
    }
  };
};


