import React, { useEffect } from "react";
import { fetchVideoById } from "../../../redux/actions/videoActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./videoDetails.css";

const VideoDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { videoDetails, loading, error } = useSelector((state) => state.video);

  useEffect(() => {
    dispatch(fetchVideoById(id));
  }, [dispatch, id]);
  const getYouTubeVideoId = (url) => {
    const match = url.match(/(?:\?|\&)v=([^&]+)/);
    return match ? match[1] : null;
  };

  if (loading)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է ...</p>
    );
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="videoDetails-container">
      {videoDetails && (
        <>
          <div className="videoDetails-header">
            {videoDetails.youtube_link && (
                <iframe
                    className="youTube-video"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(videoDetails.youtube_link)}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
            <div className="videoDetails-info">
              <p className="videoDetails-date">
                {" "}
                {videoDetails.created_at.split("T")[0]}
              </p>
              <h1 className="videoDetails-title">{videoDetails.title}</h1>
            </div>
          </div>
          <p
            className="videoDetails-desc"
            dangerouslySetInnerHTML={{ __html: videoDetails.content }}
          ></p>
        </>
      )}
    </div>
  );
};

export default VideoDetails;
