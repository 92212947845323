import axios from "axios";

export const RATE_USER_REQUEST = "RATE_USER_REQUEST";
export const RATE_USER_SUCCESS = "RATE_USER_SUCCESS";
export const RATE_USER_FAILURE = "RATE_USER_FAILURE";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Request action for starting the rating process
const rateUserRequest = () => ({ type: RATE_USER_REQUEST });

// Success action once the rating is successfully submitted
const rateUserSuccess = (message) => ({
  type: RATE_USER_SUCCESS,
  payload: { message },
});

// Failure action if something goes wrong
const rateUserFailure = (error) => ({
  type: RATE_USER_FAILURE,
  payload: error,
});

// Action to rate a user by ID
export const rateUserById = (id, rate, ip) => {
  return async (dispatch) => {
    dispatch(rateUserRequest());
    try {
      const response = await axios.post(`${API_BASE_URL}/api/judo-users/${id}/rate`, {
        rate: rate,
        ip: ip
      });

      // Dispatch success action with the success message from API
      dispatch(rateUserSuccess(response.data.message));
    } catch (error) {
      // Dispatch failure action with error message
      dispatch(rateUserFailure(error.response?.data?.message || error.message));
    }
  };
};
