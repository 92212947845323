import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const FETCH_VIDEO_REQUEST = "FETCH_VIDEO_REQUEST";
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS";
export const FETCH_VIDEO_FAILURE = "FETCH_VIDEO_FAILURE";
export const FETCH_VIDEO_DETAILS_REQUEST = "FETCH_VIDEO_DETAILS_REQUEST";
export const FETCH_VIDEO_DETAILS_SUCCESS = "FETCH_VIDEO_DETAILS_SUCCESS";
export const FETCH_VIDEO_DETAILS_FAILURE = "FETCH_VIDEO_DETAILS_FAILURE";

const fetchVideoRequest = () => ({ type: FETCH_VIDEO_REQUEST });
const fetchVideoSuccess = (video) => ({
  type: FETCH_VIDEO_SUCCESS,
  payload: video ,
});
const fetchVideoFailure = (error) => ({
  type: FETCH_VIDEO_FAILURE,
  payload: error,
});

export const fetchVideo = (page = 1, date = null, search = "") => {
  return async (dispatch) => {
    dispatch(fetchVideoRequest());
    try {
      const response = await axios.get(`${API_BASE_URL}/api/youtube-links`, {
        params: {
          sort: "created_at",
          direction: "desc",
          page: page,
          date: date,
          search: search
        },
      });
      
      const video = response.data ? response.data : [];

      dispatch(fetchVideoSuccess(video));
    } catch (error) {
      dispatch(fetchVideoFailure(error.message));
    }
  };
};

const fetchVideoDetailsRequest = () => ({
  type: FETCH_VIDEO_DETAILS_REQUEST,
});
const fetchVideoDetailsSuccess = (videoDetails) => ({
  type: FETCH_VIDEO_DETAILS_SUCCESS,
  payload: videoDetails,
});
const fetchVideoDetailsFailure = (error) => ({
  type: FETCH_VIDEO_DETAILS_FAILURE,
  payload: error,
});

export const fetchVideoById = (id) => {
  return async (dispatch) => {
    dispatch(fetchVideoDetailsRequest());
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/youtube-links/${id}`
      );
      const videoDetails = response.data;
      dispatch(fetchVideoDetailsSuccess(videoDetails));
    } catch (error) {
      dispatch(fetchVideoDetailsFailure(error.message));
    }
  };
};
