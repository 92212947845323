import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_DETAILS_REQUEST,
  FETCH_EVENTS_DETAILS_SUCCESS,
  FETCH_EVENTS_DETAILS_FAILURE,
} from "../actions/eventsActions";

const initialState = {
  events: [],
  top_events: [],
  eventsDetail: null,
  loading: false,
  error: null,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
    case FETCH_EVENTS_DETAILS_REQUEST:
      return { ...state, loading: true };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.events,
        top_events: action.payload.top_events,
      };

    case FETCH_EVENTS_DETAILS_SUCCESS:
      return { ...state, loading: false, eventsDetail: action.payload };

    case FETCH_EVENTS_FAILURE:
    case FETCH_EVENTS_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default eventsReducer;
