import axios from "axios";

export const RATE_PLACE_REQUEST = "RATE_PLACE_REQUEST";
export const RATE_PLACE_SUCCESS = "RATE_PLACE_SUCCESS";
export const RATE_PLACE_FAILURE = "RATE_PLACE_FAILURE";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Request action for starting the rating process
const ratePlaceRequest = () => ({ type: RATE_PLACE_REQUEST });

// Success action once the rating is successfully submitted
const ratePlaceSuccess = (message) => ({
  type: RATE_PLACE_SUCCESS,
  payload: { message },
});

// Failure action if something goes wrong
const ratePlaceFailure = (error) => ({
  type: RATE_PLACE_FAILURE,
  payload: error,
});

// Action to rate a place by ID
export const ratePlaceById = (id, rate, ip) => {
  return async (dispatch) => {
    dispatch(ratePlaceRequest());
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/places/${id}/rate`,
        {
          rate: rate,
          ip: ip,
        }
      );

      // Dispatch success action with the success message from API
      dispatch(ratePlaceSuccess(response.data.message));
    } catch (error) {
      // Dispatch failure action with error message
      dispatch(
        ratePlaceFailure(error.response?.data?.message || error.message)
      );
    }
  };
};
