import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, DatePicker, Input, Pagination } from "antd";
import { fetchVideo } from "../../redux/actions/videoActions";
import noImage from "../../assets/No_Image_Available.jpg";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import "./video.css";
const { Search } = Input;
const Video = () => {
  const dispatch = useDispatch();
  const { video, loading, error } = useSelector((state) => state.video);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    dispatch(fetchVideo(currentPage, selectedDate, ""));
  }, [dispatch, currentPage, selectedDate]);

  if (loading)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է ...</p>
    );
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    dispatch(fetchVideo(currentPage, selectedDate, value));
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setSelectedDate(formattedDate);
    dispatch(fetchVideo(currentPage, formattedDate, searchTerm));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="datepicker-container">
        <Search
          className="input"
          placeholder="Որոնել ըստ վերնագրի "
          enterButton={<SearchOutlined />}
          value={searchTerm}
          onSearch={handleSearchChange}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <DatePicker
          className="datePicker"
          placeholder="Որոնել ըստ ամսաթվի"
          onChange={handleDateChange}
        />
      </div>
      <div className="video-container">
        {video.data && video.data?.length > 0 ? (
          video.data.map((item) => (
            <Link
              to={`/results/${item.id}`}
              key={item.id}
              style={{ textDecoration: "none" }}
            >
              <Card
                key={item.id}
                className="video-card"
                hoverable
                cover={
                  item.images && item.image_url.length > 0 ? (
                    <img
                      src={item.image_url}
                      alt={item.title}
                      className="image"
                    />
                  ) : (
                    <img
                      className="image"
                      alt="No Image Available"
                      src={noImage}
                      style={{ objectFit: "cover" }}
                    />
                  )
                }
              >
                <Card.Meta
                  description={
                    <div>
                      <p className="video-date">
                        {item.created_at?.split("T")[0]}
                      </p>
                      <p className="video-title">{item.title}</p>
                      <p
                        className="video-desc"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p>
                    </div>
                  }
                />
              </Card>
            </Link>
          ))
        ) : (
          <p>Ոչինչ չի գտնվել</p>
        )}
      </div>

      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={video.total || 0}
        onChange={handlePageChange}
        className="pagination"
      />
    </>
  );
};

export default Video;
