import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchNewsById } from "../../../redux/actions/newsActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "./newsDetail.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const NewsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { newsDetail, loading, error } = useSelector((state) => state.news);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    dispatch(fetchNewsById(id));
  }, [dispatch, id]);

  if (loading)
    return <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>;
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="newsDetails-container">
      {newsDetail ? (
        <>
          <div className="newsDetails-header">
            {newsDetail.images.length > 0 && (
              <img
                className="mainImage"
                src={`${API_BASE_URL}/storage/${newsDetail.images[0].file_path}`}
                alt={newsDetail.title}
                onClick={() =>
                  openModal(
                    `${API_BASE_URL}/storage/${newsDetail.images[0].file_path}`
                  )
                }
              />
            )}

            <div className="newsDetails-info">
              <p className="dateDetail">
                {newsDetail.created_at.split("T")[0]}
              </p>
              <h1 className="detailsTitle">{newsDetail.title}</h1>
            </div>
          </div>

          <div className="newsDetails-content">
            <p
              className="content"
              dangerouslySetInnerHTML={{ __html: newsDetail.content }}
            />
          </div>

          {newsDetail.images.length > 1 && (
            <div className="newsDetails-images">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  400: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {newsDetail.images.slice(1).map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="detailsImg"
                      src={`${API_BASE_URL}/storage/${image.file_path}`}
                      alt={image.title}
                      onClick={() =>
                        openModal(`${API_BASE_URL}/storage/${image.file_path}`)
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      ) : (
        <p>Նորություններ չկան</p>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedImage} className="modal-img" alt="Zoomed" />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsDetails;
