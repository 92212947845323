import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_DETAILS_REQUEST,
    FETCH_USERS_DETAILS_SUCCESS,
    FETCH_USERS_DETAILS_FAILURE,
} from "../actions/usersActions";
import {RATE_USER_FAILURE, RATE_USER_REQUEST, RATE_USER_SUCCESS} from "../actions/rateActions";

const initialState = {
    users: [],
    topUsers: [],
    usersDetail: null,
    loading: false,
    error: null,
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
        case FETCH_USERS_DETAILS_REQUEST:
            return {...state, loading: true};

        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                topUsers: action.payload.topUsers,
            };

        case FETCH_USERS_DETAILS_SUCCESS:
            return {...state, loading: false, usersDetail: action.payload};

        case FETCH_USERS_FAILURE:
        case FETCH_USERS_DETAILS_FAILURE:
            return {...state, loading: false, error: action.payload};

        case RATE_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                rateMessage: action.payload.message,
            };

        case RATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default usersReducer;
