import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { fetchEvents } from "../../redux/actions/eventsActions";
import { Card, DatePicker, Input, Pagination, Select } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination as SwiperPagination,
} from "swiper/modules";
import { SearchOutlined } from "@ant-design/icons";
import "swiper/swiper-bundle.css";
import noImage from "../../assets/No_Image_Available.jpg";
import "./events.css";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Events = () => {
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);
  const location = useLocation();
  const category = location.pathname.split("/")[3] || "";
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [sex, setSex] = useState("all");

  useEffect(() => {
    const { startDate, endDate } = selectedDate || {};
    dispatch(fetchEvents(category, currentPage, startDate, endDate, "", sex));
  }, [dispatch, category, currentPage, selectedDate, sex]);

  if (loading)
    return <p style={{ textAlign: "center", height: "100vh" }}>Բեռնում է...</p>;
  if (error)
    return (
      <p style={{ textAlign: "center", height: "100vh" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  const handleSearchChange = (value) => {
    const { startDate, endDate } = selectedDate || {};
    setSearchTerm(value);
    dispatch(
      fetchEvents(category, currentPage, startDate, endDate, value, sex)
    );
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setSelectedDate({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });
    } else {
      setSelectedDate(null);
    }
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const topEvents = events.data?.filter((item) => item.top_event === 1) || [];
  const regularEvents =
    events.data?.filter((item) => item.top_event === 0) || [];

  return (
    <>
      <div className="datepickerEvents-container">
        <Select
          className="selectSex"
          defaultValue="all"
          value={sex}
          onChange={(value) => setSex(value)}
        >
          <option value="all">Բոլորը</option>
          <option value="female">Իգական</option>
          <option value="male">Արական</option>
        </Select>
        <Search
          className="input"
          placeholder="Որոնել"
          enterButton={<SearchOutlined />}
          value={searchTerm}
          onSearch={handleSearchChange}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <RangePicker
          className="datePicker"
          placeholder="Որոնել ըստ ամսաթվի"
          onChange={handleDateChange}
        />
      </div>
      <div className="top-events-container">
        <div className="top-events">
          {topEvents.length > 0 && (
            <Swiper
              modules={[Navigation, Autoplay, SwiperPagination]}
              spaceBetween={20}
              slidesPerView={4}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                400: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                200: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
            >
              {topEvents.map((item) => (
                <SwiperSlide key={item.id}>
                  <Link
                    to={`/events/${item.id}`}
                    key={item.id}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      className="cardTopEvents"
                      cover={
                        item.images && item.images.length > 0 ? (
                          <img
                            className="image"
                            alt={item.title}
                            src={item.images[0].file_path}
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <img
                            className="image"
                            alt="No Image Available"
                            src={noImage}
                            style={{ objectFit: "cover" }}
                          />
                        )
                      }
                    >
                      <Card.Meta
                        description={
                          <div className="contentEvents">
                            <p className="eventstitle">{item.title}</p>

                            <p className="dateEvent">
                              <span>Սկիզբ։</span>{" "}
                              {item.start_date.split(":").slice(0, 2).join(":")}
                            </p>

                            {item.start_date
                              .split(":")
                              .slice(0, 2)
                              .join(":") !==
                              item.end_date
                                .split(":")
                                .slice(0, 2)
                                .join(":") && (
                              <p className="dateEvent">
                                <span>Ավարտ։</span>{" "}
                                {item.end_date.split(":").slice(0, 2).join(":")}
                              </p>
                            )}

                            <p className="dateEvent">
                              <span>Անցկացման վայրը։</span> {item.place_country}
                              ,{item.place_city},{item.place_address}
                            </p>
                            {/* {item.files && item.files.length > 0 && (
                              <p className="dateEvent">
                                <span>Ֆայլի հղում՝ </span>
                                <a
                                  href={item.files[0].file_path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.files[0].file_path}
                                </a>
                              </p>
                            )} */}
                          </div>
                        }
                      />
                    </Card>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        <div className="regular-events-container">
          {regularEvents.length > 0
            ? regularEvents.map((item) => (
                <Link
                  to={`/events/${item.id}`}
                  key={item.id}
                  style={{ textDecoration: "none" }}
                >
                  <Card className="cardRegEvents" key={item.id}>
                    <Card.Meta
                      description={
                        <div className="contentEvents">
                          <p className="eventstitle">{item.title}</p>

                          <p className="dateEvent">
                            <span>Սկիզբ։</span>{" "}
                            {item.start_date.split(":").slice(0, 2).join(":")}
                          </p>

                          {item.start_date.split(":").slice(0, 2).join(":") !==
                            item.end_date.split(":").slice(0, 2).join(":") && (
                            <p className="dateEvent">
                              <span>Ավարտ։</span>{" "}
                              {item.end_date.split(":").slice(0, 2).join(":")}
                            </p>
                          )}

                          <p className="dateEvent">
                            <span>Անցկացման վայրը։</span> {item.place_country},
                            {item.place_city},{item.place_address}
                          </p>
                        </div>
                      }
                    />
                  </Card>
                </Link>
              ))
            : ""}
        </div>

        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={events.total || 0}
          onChange={handlePageChange}
          className="pagination"
        />
      </div>
    </>
  );
};

export default Events;
