import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EMAIL_SEND_REQUEST = 'EMAIL_SEND_REQUEST';
export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS';
export const EMAIL_SEND_FAILURE = 'EMAIL_SEND_FAILURE';

const emailSendRequest = () => ({ type: EMAIL_SEND_REQUEST });
const emailSendSuccess = (response) => ({
  type: EMAIL_SEND_SUCCESS,
  payload: response,
});
const emailSendFailure = (error) => ({
  type: EMAIL_SEND_FAILURE,
  payload: error,
});


export const sendEmail = (emailData) => {
  return async (dispatch) => {
    dispatch(emailSendRequest());
    try {
      const response = await axios.post(`${API_BASE_URL}/api/send-email`, emailData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      dispatch(emailSendSuccess(response.data)); 
    } catch (error) {
      dispatch(emailSendFailure(error.message)); 
    }
  };
};
