import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./slider.css";
import { fetchNews } from "../../redux/actions/newsActions";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const SliderComponent = () => {
  const dispatch = useDispatch();
  const { top_news, loading, error } = useSelector((state) => state.news);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  if (loading)
    return (
      <p style={{ textAlign: "center", margin: "100px" }}>Բեռնում է ...</p>
    );
  if (error)
    return (
      <p style={{ textAlign: "center", margin: "100px" }}>
        Ներողություն, առկա է տեխնիկական խնդիր
      </p>
    );

  return (
    <div className="slider-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        spaceBetween={30}
        slidesPerView={3}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {top_news && top_news.length > 0 ? (
          top_news.map((item) => (
            <SwiperSlide key={item.id} className="slider-item">
              <img
                style={{ objectFit: "cover" }}
                className="sliderImage"
                src={`${API_BASE_URL}/storage/${item.images[0].file_path}`}
                alt={item.title}
              />
              <div className="slider-content">
                <h2>{item.title}</h2>
                <p
                  className="slider-desc"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></p>

                <Link to={`/news/${item.id}`}>
                  <button className="button">Կարդալ ավելին</button>
                </Link>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <p>Նորություններ չկան</p>
        )}
      </Swiper>
    </div>
  );
};

export default SliderComponent;
