import axios from 'axios';

export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchMembersRequest = () => {
    return {
        type: FETCH_MEMBERS_REQUEST,
    };
};

export const fetchMembersSuccess = (members) => {
    return {
        type: FETCH_MEMBERS_SUCCESS,
        payload: members,
    };
};

export const fetchMembersFailure = (error) => {
    return {
        type: FETCH_MEMBERS_FAILURE,
        payload: error,
    };
};

export const fetchMembers = () => {
    return (dispatch) => {
        dispatch(fetchMembersRequest());
        axios
            .get(`${API_BASE_URL}/api/organization-tree`)
            .then((response) => {
                const members = response.data;
                dispatch(fetchMembersSuccess(members));
            })
            .catch((error) => {
                const errorMsg = error.message;
                dispatch(fetchMembersFailure(errorMsg));
            });
    };
};
