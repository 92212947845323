import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Input, Spin, Alert } from "antd";
import { sendEmail } from "../../../redux/actions/emailActions";
import headImg from "../../../assets/judo-head.png";
import "./president.css";

const { TextArea } = Input;

const President = () => {
  const [emailData, setEmailData] = useState({
    phone: "",
    email: "",
    first_name: "",
    last_name: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { emailInfo, loading, error } = useSelector((state) => state.emailSend);

  const handleChange = (e) => {
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (emailInfo && emailInfo?.message != "") {
      Modal.success({
        title: "Շնորհակալություն",
        content: "Ձեր նամակը հաջողությամբ ուղարկվել է!",
      });
      setIsModalOpen(false);
      emailInfo.message = "";
    }
  }, [emailInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendEmail(emailData));
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="headContainer">
      <div className="headTitle">Նախագահի անկյուն</div>

      <div className="head">
        <div className="headLeft">
          <img src={headImg} className="headImg" />
          <div className="headInfo">
            Վարդան Սիրեկանի Ոսկանյան
            <p>ՀՀ Ձյուդոյի Ֆեդերացիայի նախագահ</p>
            <p>Ծնվել է՝ 01.01.1972 թ. ք.Երևան, Նուբարաշեն </p>
            <p>
              Ավարտել է Երևանի թիվ 95 միջնակարգ դպրոցը
              <br />
              և Երևանի պետական ֆիզիկական կուլտուրայի
              <br />
              ինստիտուտը:
            </p>
          </div>
        </div>

        <span className="sportAchievements">
          <strong>Սպորտային նվաճումներ</strong>
          <br />
          1989 թ. ԽՍՀՄ սամբոյի առաջնության հաղթող
          <br />
          1990 թ. Աշխարհի առաջնության հաղթող
          <br />
          1993 թ. ՍՍՀՄ սամբոյի առաջնություն – 3-րդ տեղ
          <br />
          1994 թ. Ռուսաստանի ձյուդոյի առաջնություն – 3-րդ տեղ
          <br />
          1995 թ. Զինծառայողների ձյուդոյի համաշխարհային խաղերի հաղթող
          <br />
          1995 թ. Ռուսաստանի ձյուդոյի առաջնություն – 1-ին տեղ
          <br />
          1995 թ. Սեուլի միջազգային մրցաշար – 1-ին տեղ
          <br />
          1996 թ. Մոսկվայի միջազգային մրցաշար – 3-րդ տեղ
          <br />
          1996 թ. Եվրոպայի թիմային առաջնության բրոնզ – 3-րդ տեղ
          <br />
          2000 թ. Սիդնեյի Օլիմպիական խաղերի մասնակից
          <br />
          Միջազգային կարգի սպորտի վարպետ սամբոյից և ձյուդոյից
          <br />
          2011 թվականից հանդիսանում է Հայաստանի ձյուդոյի ֆեդերացիայի նախագահ:
        </span>
      </div>

      <div className="sendBtn">
        <Button className="sentButton" type="primary" onClick={handleModalOpen}>
          Ուղարկել նամակ
        </Button>
      </div>
      <Modal
        title="Ուղարկել նամակ"
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
      >
        <form onSubmit={handleSubmit}>
          <Input
            className="headInput"
            type="text"
            name="phone"
            placeholder="Հեռախոսահամար"
            value={emailData.phone}
            onChange={handleChange}
            required
            style={{ marginBottom: "10px" }}
          />
          <Input
            type="email"
            name="email"
            placeholder="Էլ․ հասցե"
            value={emailData.email}
            onChange={handleChange}
            required
            style={{ marginBottom: "10px" }}
          />
          <Input
            type="text"
            name="first_name"
            placeholder="Անուն"
            value={emailData.first_name}
            onChange={handleChange}
            required
            style={{ marginBottom: "10px" }}
          />
          <Input
            type="text"
            name="last_name"
            placeholder="Ազգանուն"
            value={emailData.last_name}
            onChange={handleChange}
            required
            style={{ marginBottom: "10px" }}
          />
          <TextArea
            name="message"
            placeholder="Հաղորդագրություն"
            value={emailData.message}
            onChange={handleChange}
            required
            rows={4}
            style={{ marginBottom: "10px" }}
          />
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            block
            disabled={loading}
          >
            {loading ? <Spin /> : "Ուղարկել"}
          </Button>
        </form>

        {error && (
          <Alert
            message="Առկա է տեխնիկական խնդիր: Խնդրում ենք փորձել մի փոքր ուշ"
            type="error"
          />
        )}
      </Modal>
    </div>
  );
};

export default President;
